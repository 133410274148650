import React from 'react';
import { Link } from "gatsby"
import {Container,Row,Col} from 'react-bootstrap';
import Footer from './footer';
import Header from './header';

const Homepagelayout = ({children,showheader}) => {

  return(
      <>
        <div className="main_div_earthly">
        <Container fluid className="p-0" style={{overflow:"hidden"}}>
            <Row>
              <Col>
              <Header/>
              <main>
                {children}
              </main>
              <Footer/>
              </Col>
            </Row>
        </Container>
        </div>
      </>
  )

}

export default Homepagelayout;
