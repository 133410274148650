import React from "react"
import { Link } from "gatsby"
import {Image,Button,Row,Col,Tab,Nav,Navbar} from 'react-bootstrap';
import Axios from 'axios';
import Qs from 'querystring'
import "./earthly.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import Homepagelayout from "../components/homepagelayout";
import Header from "../components/header";
import ModalBox from './modalbox';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import slider_one from '../images/first_slider.png';
import second_slider from '../images/second_slider.png';
import third_slider from '../images/home_page_slider.png';
import user_icon from '../images/user.png';
import user_icon_login from '../images/user_login_icon.svg';


import arrow_left from '../images/arrow-left.png'
import arrow_right from '../images/arrow-right.png'
import hero_img from '../images/earthly_twitter-card.png';

import SEO from "../components/seo"

import {API_URL,CONFIG_WITHOUT_TOKEN} from '../appconstant/index';


const settingsfirst = {
  className:"index_page",
  dots: true,
  dotsClass: "slick-dots slick-thumb",
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  asNavFor: '.slider-nav',
  autoplay: true,
  autoplaySpeed: 5000,
}

const settingstext = {
  className:"index_page_second",
  slidesToShow: 3,
  slidesToScroll: 1,
  asNavFor: '.slider-for',
  dots: false,
  centerMode: true,
  swipeToSlide: true,
  focusOnSelect: true,
  centerPadding: '10px',
  vertical:true,
  infinite: true,
}

class IndexPage extends React.Component{
  constructor(props) {
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleMainSelect = this.handleMainSelect.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }
  state = {
    shownabar:"visible",
    eventKeypress:"first",
    showheader:"hidden",
    modalshow:false,
    nav1: null,
    nav2: null,
    successmessage:"",
    errormessage:"",
    email_value:"",
    user_is_login:"",
    insurl:"",
  }
  componentDidMount() {
    const {nav1,nav2,insurl} = this.state;
    this.setState({
         nav1: this.slider1,
         nav2: this.slider2,
         user_is_login:localStorage.getItem('earthly_web_token'),
       });
       if(navigator.userAgent.indexOf("Chrome") != -1 ){
           this.setState({insurl:"https://chrome.google.com/webstore/detail/earthly/kblhgfjcdibpokglmdpopbkbiaknakii"});
       }else{
           this.setState({insurl:"https://apps.apple.com/us/app/earthly-shopping-assistant/id1577359249?mt=12"});
       }
  }


  handleSelect(event){
      const {eventKeypress} = this.state
      if(this.state.eventKeypress == "first"){
        this.setState({eventKeypress:"second"});
      }
  }
  handleMainSelect(eventKey){
    this.setState({eventKeypress:eventKey})
  }

openModal = (e) =>{
    const {modalshow,successmessage,errormessage,email_value} = this.state;
    this.setState({modalshow:true,successmessage:"",errormessage:"",email_value:""})

}
closeModal = (e) =>{
  const {modalshow} = this.state;
  this.setState({modalshow:false})
}
nextclick = () =>{
  this.slider1.slickNext();
}
prvclick = () =>{
  this.slider1.slickPrev();
}
inputChange = (e) =>{
  const {email_value} = this.state;
  this.setState({email_value:e.target.value})
}
formSubmit(e){
  e.preventDefault()
  let submit_email_value = e.target.email.value;
  let email_data = {};
  email_data.email = submit_email_value;
  const {successmessage,errormessage} = this.state;
  Axios.post(API_URL + '/apis/waitlist',Qs.stringify(email_data),CONFIG_WITHOUT_TOKEN)
  .then((response) =>  {
      if(response.data.error == 0){
          this.setState({successmessage:response.data.message,errormessage:"",email_value:""});
          setTimeout(() => {
              this.setState({modalshow:false});
          }, 2000 )
      }else if(response.data.error == 1){
        this.setState({errormessage:response.data.message,successmessage:""});
      }
  })
  .catch((error) =>  {
    this.setState({errormessage:error.message,successmessage:""});
  });
}
  render(){
    const {
      shownabar,
      activetab,
      eventKeypress,
      showheader,
      modalshow,
      handleClose,
      nav1,
      nav2,
      successmessage,
      errormessage,
      user_is_login,
      insurl
    } = this.state;

    const {
      show,
      onsubmit
    } = this.props;
    return(
      <Homepagelayout showheader={this.state.showheader}>
        <SEO title="Identify Non-Toxic, Cruelty-Free, Palm oil-free, Vegan, Microbead-free products on Amazon" image={hero_img} type="website" />
            <div className="row content_first_section first_section_bg_img">
                <div className="col-md-9">
                      <div className="row min-vh-100 justify-content-center align-items-center heading_section">
                        <div className="col-md-11 ml-3 mt-3">
                          <h1 className="title_header">Shop consciously. <br/> Live better. <br/>  Save the planet.</h1>
                          <p className="sub_title col-md-8 col-lg-6 col-sm-10 col-11">Shop on Amazon.com with Earthly’s extension added to your browser, and confidently buy products that are better for you and our planet</p>
                          <div className="col-md-7 col-sm-5 col-lg-5 row butn_mobile">
                            <a className="add_to_cart btn btn-md" href={this.state.insurl} role="button">Add to Browser - It's Free</a>
                          </div>
                        </div>
                      </div>
                </div>
            </div>
            <div className="second_section">
              <div className="row how_works_sec">
                <div className="col-lg-6 col-md-12 col-sm-12">
                <Slider
                  {...settingsfirst}
                  asNavFor={this.state.nav2}  ref={slider => (this.slider1 = slider)}>
                  <div>
                      <img className="img-fluid"  src={slider_one}/>
                  </div>
                  <div>
                      <img className="img-fluid" src={second_slider}/>
                  </div>
                  <div>
                    <img className="img-fluid"  src={third_slider}/>
                  </div>
                </Slider>
                  <div style={{display:"flex",flexDirection:"row"}} className="custon_arrw">
                      <div className="first_div_left">
                          <button className="custom_arrow_btn" onClick={this.prvclick}>
                            <img src={arrow_left} alt="next" />
                          </button>
                      </div>
                      <div className="second_div_left">
                            <button className="custom_arrow_btn"  onClick={this.nextclick}>
                              <img src={arrow_right} alt="next" />
                            </button>
                      </div>
                  </div>
                </div>
                <div className="ml-2 col-lg-4 col-md-10 col-sm-12 mt-3">
                <div className ="justify-content-center align-items-center">
                  <h1 className="title_header_second_section">How it works:</h1>
                </div>
                  <Slider
                    {...settingstext}
                     asNavFor={this.state.nav1}
                     ref={slider => (this.slider2 = slider)}>
                     <div className="mt-2">
                      <div style={{display:"flex"}} className="mt-2 mb-2 slider_content_index">
                        <div>
                            <span className="tab_number">1</span>
                        </div>
                        <div>
                          <label className="content_slider">Add Earthly’s shopping extension to your browser in seconds</label>
                        </div>
                      </div>
                     </div>
                     <div className="mt-2">
                       <div style={{display:"flex"}} className="mt-2 mb-2 slider_content_index">
                         <div>
                            <span className="tab_number">2</span>
                         </div>
                         <div>
                            <label className="content_slider">Shop as usual while Earthly labels and recommends better products</label>
                          </div>
                       </div>
                     </div>
                     <div className="mt-2">
                       <div style={{display:"flex"}} className="mt-2 mb-2 slider_content_index">
                          <div>
                              <span className="tab_number">3</span>
                          </div>
                          <div>
                              <label className="content_slider">Buy greener and track your contribution to saving the planet</label>
                           </div>
                        </div>
                     </div>
                  </Slider>
                </div>
              </div>
            </div>
            <ModalBox
            show={this.state.modalshow}
            handleClose={this.closeModal}
            onsubmit={this.formSubmit}
            emessage={this.state.errormessage}
            smessage={this.state.successmessage}
            email={this.state.email_value}
            oninputchange={this.inputChange}  />
      </Homepagelayout>
    )
  }
}


export default IndexPage
